import { faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FC } from 'react'

interface CopyRightProps {
  className?: string
  isLink?: boolean
  openSourceRepository?: string
}

export const CopyRight: FC<CopyRightProps> = ({
  className,
  isLink = true,
  openSourceRepository,
}) => (
  <footer
    className={classNames('absolute bottom-2 right-4 rounded', className)}
  >
    <a
      href={isLink ? 'https://brecht.pallemans.com' : undefined}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="inline-block align-middle mr-1">
        2022 © Created and secured by
      </span>
      <span className=" font-bold inline-block align-middle mr-1">
        Brecht Pallemans.
      </span>
      {!openSourceRepository && (
        <span className="inline-block align-middle">All rights reserved.</span>
      )}
    </a>
    <img
      src={require('assets/brecht_pallemans_logo.png')}
      className="w-5 h-5 inline-block align-middle ml-1"
    />
    {openSourceRepository && (
      <a
        href={openSourceRepository}
        target="_blank"
        rel="noopener noreferrer"
        className="ml-3"
      >
        <span className="inline-block align-middle mr-1">
          View this website on
        </span>
        <FontAwesomeIcon
          icon={openSourceRepository.includes('gitlab') ? faGitlab : faGithub}
          className="inline-block align-middle"
        />
      </a>
    )}
  </footer>
)
