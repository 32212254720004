import { io } from 'socket.io-client'

export const getSocket = (url?: string) => {
  const { origin, pathname } = new URL(url || 'http://localhost')

  return io(origin, {
    path: `${pathname.length > 1 ? `${pathname}/` : '/'}socket.io`,
    transports: ['websocket', 'polling'],
  })
}
