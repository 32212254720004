export const routes = {
  admin: '/admin',
  default: '/',
  discount: '/kiosk/:kioskId/discount',
  download: '/download/:photoId',
  kiosk: '/kiosk/:kioskId',
  kioskAdmin: '/admin/kiosk/:kioskId',
  payment: '/kiosk/:kioskId/payment',
  paymentWithCode: '/kiosk/:kioskId/payment/:discountCode',
  photoautomat: '/kiosk/:kioskId/automat',
  photoEffect: '/kiosk/:kioskId/photoEffect',
  print: '/kiosk/:kioskId/print',
  wildCard: '*',
}
