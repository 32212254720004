interface Window {
  config: {
    REACT_APP_BACKEND_URL: string
    REACT_APP_KEYCLOAK_CLIENT_ID: string
    REACT_APP_KEYCLOAK_REALM_NAME: string
    REACT_APP_KEYCLOAK_SERVICE_URL: string
  }
}
declare const window: Window

export const isDev = process.env.ENVIRONMENT !== 'production'

export const BACKEND_URL = isDev
  ? process.env.REACT_APP_BACKEND_URL
  : window.config.REACT_APP_BACKEND_URL

export const { ENVIRONMENT } = process.env

export const KEYCLOAK_REALM_NAME = isDev
  ? process.env.REACT_APP_KEYCLOAK_REALM_NAME
  : window.config.REACT_APP_KEYCLOAK_REALM_NAME

export const KEYCLOAK_SERVICE_URL = isDev
  ? process.env.REACT_APP_KEYCLOAK_SERVICE_URL
  : window.config.REACT_APP_KEYCLOAK_SERVICE_URL

export const KEYCLOAK_CLIENT_ID = isDev
  ? process.env.REACT_APP_KEYCLOAK_CLIENT_ID
  : window.config.REACT_APP_KEYCLOAK_CLIENT_ID
