import { useKioskContext } from 'context/KioskContext'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const supportPhoneNumber = '0897332910'

export const PaymentError: FC = () => {
  const { t } = useTranslation()
  const { kiosk } = useKioskContext()

  return (
    <div className="border border-4 rounded-lg border-secondary-focus mx-auto relative p-4">
      <span className="text-base-content  whitespace-pre-line">
        {t('payment.error')}
      </span>
      <div>
        <span className="text-base-content mr-1">
          {t('payment.errorPreContact')}
          <span className="text-primary font-bold mx-1">
            {kiosk?.contact || supportPhoneNumber}
          </span>
          {t('payment.errorPostContact')}
        </span>
      </div>
    </div>
  )
}
