import classNames from 'classnames'
import { Billing } from 'components/Billing'
import { MyKiosks } from 'components/MyKiosks'
import { FC, useState } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { AdminTab } from 'types'

export const Admin: FC = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(AdminTab.MY_KIOSKS)

  return (
    <FullScreen className="bg-base-100">
      <div className="tabs tabs-boxed bg-base-100 justify-center">
        <a
          className={classNames(
            'tab',
            tab === AdminTab.MY_KIOSKS && 'tab-active'
          )}
          onClick={() => setTab(AdminTab.MY_KIOSKS)}
        >
          {t(`tabs.${AdminTab.MY_KIOSKS.valueOf()}`)}
        </a>
        <a
          className={classNames(
            'tab',
            tab === AdminTab.BILLING && 'tab-active'
          )}
          onClick={() => setTab(AdminTab.BILLING)}
        >
          {t(`tabs.${AdminTab.BILLING.valueOf()}`)}
        </a>
      </div>
      {tab === AdminTab.MY_KIOSKS && <MyKiosks />}
      {tab === AdminTab.BILLING && <Billing />}
    </FullScreen>
  )
}
