export enum SocketEvent {
  CONNECT = 'connect',
  CREATED_TRANSACTION = 'created_transaction',
  DISCONNECT = 'disconnect',
  PAYMENT_CANCELED = 'payment_canceled',
  PAYMENT_COMPLETED = 'payment_completed',
  PAYMENT_PROCESSING = 'payment_processing',
  PAYMENT_START = 'payment_start',
  TRANSACTION_ERROR = 'transaction_error',
}
