import { BACKEND_URL } from 'config'
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Socket } from 'socket.io-client'
import { getSocket } from 'utils'

export interface ISocketContext {
  socket?: Socket
}

export const SocketContext = createContext<ISocketContext>({})

export const SocketProvider: FC = ({ children }) => {
  const socket = useRef<Socket | undefined>()
  const [state, setState] = useState<ISocketContext>({})

  useEffect(() => {
    if (!socket.current) {
      socket.current = getSocket(BACKEND_URL)
    }
    setState({
      socket: socket.current,
    })
  }, [])

  return (
    <SocketContext.Provider value={state}>{children}</SocketContext.Provider>
  )
}

export const useSocket = (): ISocketContext => useContext(SocketContext)
