import { routes } from 'config'
import { useKioskContext } from 'context/KioskContext'
import { useSocket } from 'context/SocketContext'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkTransaction } from 'services'
import { SocketEvent } from 'types'

export const useWaitForPayment = () => {
  const navigate = useNavigate()
  const { kioskId } = useParams()
  const { transactionId } = useKioskContext()
  const { socket } = useSocket()
  const [to, setTo] = useState<NodeJS.Timeout | undefined>()

  const onPaymentCompleted = useCallback(() => {
    navigate(routes.photoautomat.replace(':kioskId', kioskId || ''))
  }, [navigate, kioskId])

  useEffect(() => {
    if (kioskId && transactionId && !to) {
      const to = setTimeout(() => {
        checkTransaction(kioskId, transactionId).then((e) => {
          if (e.data.paid) {
            onPaymentCompleted()
          }
          setTo(undefined)
        })
      }, 8000)
      setTo(to)
    }
  }, [kioskId, transactionId, to, setTo, onPaymentCompleted])

  useEffect(() => {
    socket?.on(SocketEvent.PAYMENT_COMPLETED, onPaymentCompleted)

    return () => {
      socket?.off(SocketEvent.PAYMENT_COMPLETED, onPaymentCompleted)
    }
  }, [socket, onPaymentCompleted])

  return onPaymentCompleted
}
