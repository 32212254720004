import { Loading } from 'components/base/Loading'
import { routes } from 'config'
import { useKioskContext } from 'context/KioskContext'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const KioskLoader: FC = ({ children }) => {
  const { kioskId } = useParams()
  const navigate = useNavigate()
  const { setKiosk, kiosk, loadKiosk } = useKioskContext()

  useEffect(() => {
    if (kioskId && !kiosk)
      loadKiosk(kioskId).catch(() =>
        navigate(`${routes.kiosk.replace(':kioskId', kioskId)}/not-found`)
      )
  }, [kioskId, kiosk, loadKiosk, navigate, setKiosk])

  return kiosk ? <>{children}</> : <Loading className="bg-base-100" />
}
