import { useKeycloak } from '@react-keycloak/web'
import { Loading } from 'components/base/Loading'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

export const PrivateRoute: FC = () => {
  const { keycloak, initialized } = useKeycloak()

  if (!initialized) {
    return <Loading className="bg-base-100" />
  }
  if (!keycloak.authenticated) {
    keycloak.login()
    return <Loading className="bg-base-100" />
  }
  keycloak.loadUserProfile()

  return <Outlet />
}
