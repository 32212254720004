import axios, { AxiosRequestConfig } from 'axios'
import { BACKEND_URL } from 'config'
import { KioskForm } from 'types'
import { keycloak } from 'utils'

const keycloakInterceptor = (requestConfig: AxiosRequestConfig) => {
  const token = keycloak?.token || ''
  if (!token) return requestConfig
  return {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: `Bearer ${token}`,
    },
  }
}

const backendInstance = axios.create({ baseURL: BACKEND_URL })
backendInstance.interceptors.request.use(keycloakInterceptor)

export const uploadPhoto = async (
  file: File,
  kioskId: string,
  transactionId?: string
) => {
  const formData = new FormData()
  formData.append('file', file)
  if (transactionId) formData.append('transactionId', transactionId)
  return backendInstance.post(`/kiosks/${kioskId}/photos`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getPhoto = async (id: string) =>
  backendInstance.get(`/photos/${id}`)

export const getPhotos = async (kioskId: string) =>
  backendInstance.get(`/kiosks/${kioskId}/photos`)

export const getKiosk = async (id: string) =>
  backendInstance.get(`/kiosks/${id}`)

export const postKiosk = async (body: KioskForm) =>
  backendInstance.post('/kiosks', body)

export const putKiosk = async (id: string, body: KioskForm) =>
  backendInstance.put(`/kiosks/${id}`, body)

export const getKiosks = async () => backendInstance.get('/kiosks')

export const getBills = async () => backendInstance.get('/bills')

export const deletePhoto = async (id: string) =>
  backendInstance.delete(`/photos/${id}`)

export const generateDiscountCode = async (kioskId: string) =>
  backendInstance.post(`/kiosks/${kioskId}/discounts`, { kioskId })

export const checkDiscountCode = async (kioskId: string, code: string) =>
  backendInstance.get(`/kiosks/${kioskId}/discounts/${code}`)

export const checkTransaction = async (kioskId: string, id: string) =>
  backendInstance.get(`/kiosks/${kioskId}/transactions/${id}`)

export const captureException = async (exception: Error) =>
  backendInstance.post('/exceptions', exception)
