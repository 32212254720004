import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from '@react-keycloak/web'
import { Spinner } from 'components/base/Spinner'
import { CreateKioskForm } from 'components/CreateKioskForm'
import { routes } from 'config'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getKiosks } from 'services'
import { Kiosk } from 'types'

export const MyKiosks: FC = () => {
  const { keycloak } = useKeycloak()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [kiosks, setKiosks] = useState<Kiosk[]>([])
  const [isCreating, setCreating] = useState(false)

  useEffect(() => {
    getKiosks().then(({ data }) => {
      setLoading(false)
      setKiosks(data)
    })
  }, [setKiosks])

  const onClickKiosk = useCallback(
    (kiosk: Kiosk) => navigate(routes.kioskAdmin.replace(':kioskId', kiosk.id)),
    [navigate]
  )

  const onClickCreateKiosk = useCallback(() => setCreating(true), [setCreating])
  const onCancelCreateKiosk = useCallback(
    () => setCreating(false),
    [setCreating]
  )

  return (
    <>
      <div className="pt-8 md:pt-16 pb-2">
        <h1 className="text text-3xl text-center">
          {isCreating ? t('admin.newKiosk.title') : t('admin.myKiosks')}
        </h1>
      </div>
      {isCreating ? (
        <CreateKioskForm
          defaultValues={{
            priceAmount: 0,
            discountAmount: 0,
            name: `${keycloak.profile?.username}_${kiosks.length + 1}`,
          }}
          isAdmin={keycloak.hasRealmRole('admin')}
          onComplete={onClickKiosk}
          onCancel={onCancelCreateKiosk}
        />
      ) : (
        <div className="h-2/3 overflow-y-scroll scroll-hidden w-full py-6">
          {loading && (
            <div className="text-center mt-5">
              <Spinner size={45} className="text-base-content" />
            </div>
          )}
          {kiosks.map((kiosk) => (
            <div key={kiosk.id} className="w-full flex flex-col">
              <button
                className="btn-secondary btn-sm w-60 mx-auto my-2 rounded-lg"
                onClick={() => onClickKiosk(kiosk)}
              >
                {kiosk.name}
              </button>
            </div>
          ))}
          {!loading && (
            <div className="w-full flex flex-col">
              <button
                className="btn-primary btn-sm w-20 mx-auto mt-4 rounded-lg"
                onClick={onClickCreateKiosk}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="text-white inline-block align-middle"
                />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
