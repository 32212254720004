import { Spinner } from 'components/base/Spinner'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBills } from 'services'
import { Bill } from 'types'

export const Billing: FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [bills, setBills] = useState<Bill[]>([])
  const [selectedBill, setSelectedBill] = useState<string | undefined>()

  useEffect(() => {
    getBills().then(({ data }) => {
      setLoading(false)
      setBills(data)
    })
  }, [setBills])

  return (
    <>
      <div className="pt-8 md:pt-16 pb-2">
        <h1 className="text text-3xl text-center">
          {t('admin.billing.title')}
        </h1>
      </div>
      <div className="h-1/2 overflow-y-scroll scroll-hidden w-full my-6">
        {loading && (
          <div className="text-center mt-5">
            <Spinner size={45} className="text-primary" />
          </div>
        )}
        {bills.length === 0 && !loading && (
          <h1 className="text text-xl text-center p-5">
            {t('admin.billing.noBills')}
          </h1>
        )}{' '}
        {bills.length > 0 && !loading && (
          <table className="table table-compact w-80 mx-auto mt-5">
            <thead>
              <tr>
                <th className="bg-accent text-white"></th>
                <th className="bg-accent text-white">
                  {t('admin.billing.month')}
                </th>
                <th className="bg-accent text-white">
                  {t('admin.billing.amount')}
                </th>
                <th className="bg-accent text-white">
                  {t('admin.billing.paid')}
                </th>
              </tr>
            </thead>
            <tbody>
              {bills.map((bill) => (
                <tr key={bill.id} onClick={() => setSelectedBill(bill.id)}>
                  <th
                    className={
                      bill.paid || bill.url || bill.transferRef
                        ? 'bg-base-100'
                        : 'bg-base-200'
                    }
                  >
                    {!bill.paid && !bill.url && !bill.transferRef && (
                      <label>
                        <input
                          type="radio"
                          checked={selectedBill === bill.id}
                          className="radio radio-accent"
                        />
                      </label>
                    )}
                  </th>
                  <td
                    className={
                      bill.paid || bill.url || bill.transferRef
                        ? 'bg-base-100'
                        : 'bg-base-200'
                    }
                  >
                    {moment(bill.periodStartAt).format('MMM')}
                  </td>
                  <td
                    className={
                      bill.paid || bill.url || bill.transferRef
                        ? 'bg-base-100'
                        : 'bg-base-200'
                    }
                  >
                    {bill.amount / 100}
                  </td>
                  {bill.paid ? ( // eslint-disable-line no-nested-ternary
                    <td className="bg-base-100">yes</td>
                  ) : bill.url || bill.transferRef ? (
                    <td className="bg-base-100">to review</td>
                  ) : (
                    <td className="bg-base-200">no</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}
