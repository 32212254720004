import { FC, useCallback, useState } from 'react'
import { generateDiscountCode } from 'services'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const GenerateCode: FC = () => {
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const { kioskId } = useParams()
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    if (kioskId)
      generateDiscountCode(kioskId)
        .then(({ data }) => setCode(data.code))
        .catch(() => setError(true))
  }, [setCode, kioskId])

  return (
    <div className="flex-grow flex flex-col justify-center">
      <h1 className="mx-auto text-primary text-6xl h-16 font-serif">{code}</h1>
      {error && (
        <span className="text-error text-3xl mx-auto">
          {t('admin.kiosk.error')}
        </span>
      )}
      <button className="btn btn-primary mx-auto mt-5" onClick={onClick}>
        {t('admin.kiosk.generateCode')}
      </button>
    </div>
  )
}
