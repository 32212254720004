import { FC, useCallback, useEffect, useState } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSocket } from 'context/SocketContext'
import { OmiseSource, SocketEvent, Transaction } from 'types'
import { routes } from 'config'
import { Spinner } from 'components/base/Spinner'
import { Loading } from 'components/base/Loading'
import { useKioskContext } from 'context/KioskContext'
import { ReactComponent as OrangeVan } from 'assets/orange-van.svg'
import { useWaitForPayment } from 'hooks'
import { PaymentError } from 'components/base/PaymentError'
import { BackButton } from 'components/base/BackButton'
import { RefundPolicy } from 'components/base/RefundPolicy'
import { CopyRight } from 'components/base/CopyRight'

interface CreatedTransactionResponse extends Transaction {
  source?: OmiseSource
}

const timerStartValue = 100

export const Payment: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { discountCode, kioskId } = useParams()
  const { socket } = useSocket()
  const [qrSource, setQrSource] = useState<OmiseSource | undefined>()
  const [loading, setLoading] = useState(true)
  const [timer, setTimer] = useState(-1)
  const [to, setTo] = useState<NodeJS.Timeout | undefined>()
  const [paymentError, setPaymentError] = useState(false)
  const [processing, setProcessing] = useState(false)

  const { setTransactionId, loadKiosk } = useKioskContext()

  useEffect(() => {
    if (kioskId && socket)
      loadKiosk(kioskId).then(() =>
        socket.emit(SocketEvent.PAYMENT_START, { discountCode, kioskId })
      )
  }, [discountCode, kioskId, loadKiosk, socket])

  const onPaymentProcessing = useCallback(() => {
    setProcessing(true)
  }, [setProcessing])

  const onPaymentCompleted = useWaitForPayment()

  const onTransactionError = useCallback(() => {
    setLoading(false)
    setPaymentError(true)
    setTimeout(() => {
      navigate(routes.kiosk.replace(':kioskId', kioskId || ''))
    }, 25000)
  }, [setLoading, setPaymentError, navigate, kioskId])

  const onCreatedTransaction = useCallback(
    (data: CreatedTransactionResponse) => {
      setTransactionId(data.id)
      if (data.source && data.source.charge_status !== 'pending') {
        onTransactionError()
      } else if (data.amount > 0) {
        setQrSource(data.source)
        setTimer(timerStartValue)
      } else onPaymentCompleted()
    },
    [setQrSource, onPaymentCompleted, setTransactionId, onTransactionError]
  )

  const onPaymentCanceled = useCallback(() => {
    setTransactionId(undefined)
    navigate(routes.kiosk.replace(':kioskId', kioskId || ''))
  }, [navigate, kioskId, setTransactionId])

  useEffect(() => {
    socket?.on(SocketEvent.CREATED_TRANSACTION, onCreatedTransaction)
    socket?.on(SocketEvent.PAYMENT_CANCELED, onPaymentCanceled)
    socket?.on(SocketEvent.PAYMENT_PROCESSING, onPaymentProcessing)
    socket?.on(SocketEvent.TRANSACTION_ERROR, onTransactionError)

    return () => {
      socket?.off(SocketEvent.CREATED_TRANSACTION, onCreatedTransaction)
      socket?.off(SocketEvent.PAYMENT_CANCELED, onPaymentCanceled)
      socket?.off(SocketEvent.PAYMENT_PROCESSING, onPaymentProcessing)
      socket?.off(SocketEvent.TRANSACTION_ERROR, onTransactionError)
    }
  }, [
    onCreatedTransaction,
    onPaymentCanceled,
    onPaymentProcessing,
    onTransactionError,
    socket,
  ])

  const onBack = useCallback(() => {
    navigate(routes.kiosk.replace(':kioskId', kioskId || ''))
  }, [navigate, kioskId])

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(onBack, 25000)

      return () => {
        clearTimeout(timeout)
      }
    }
    return () => {}
  }, [navigate, kioskId, loading, onBack])

  useEffect(() => {
    if (timer === 0 && !to) {
      onBack()
    } else if (!to && timer > 0) {
      const timeOut = setTimeout(() => {
        setTo(undefined)
        setTimer((t) => t - 1)
      }, 1000)
      setTo(timeOut)
    }
  }, [timer, to, onBack])

  return (
    <>
      <FullScreen className="bg-base-100">
        <BackButton onClick={onBack} />
        {timer > 0 && (
          <div className="absolute top-16 right-16 rounded-full w-20 h-20 border border-4 border-base-content text-center">
            <p className="text-base-content text-3xl font-[rampart] pt-3.5">
              {timer}
            </p>
          </div>
        )}
        <form
          id="CheckoutForm"
          method="POST"
          action="/charge"
          className="h-full flex flex-col justify-center"
        >
          <div className="mx-auto rounded full">
            <img
              src={require('assets/snapp_buddy_wide.png')}
              className="h-28 mx-3"
            />
          </div>
          {loading && (
            <>
              <Loading />
              <div className="h-full w-full flex flex-col justify-center"></div>
            </>
          )}
          {qrSource && (
            <div className="mx-auto h-2/3 relative mb-32">
              <img
                src={qrSource.scannable_code.image.download_uri}
                alt="Promptpay QR code"
                className="h-4/5"
                onLoad={() => {
                  setLoading(false)
                }}
              />
              {!loading && (
                <div className="p-3 pt-1 bg-white text-center">
                  <h1 className="inline-block text-2xl">
                    {t('payment.amount')}
                  </h1>
                  <h1 className="inline-block pl-1 text-2xl">
                    {qrSource.amount / 100}
                  </h1>
                  <h1 className="inline-block pl-1 text-2xl">
                    {t('payment.currency')}
                  </h1>
                </div>
              )}
              <div className="p-6 pt-0 bg-white">
                <div
                  className="bg-secondary h-1 rounded sm"
                  style={{
                    width: `${Math.max(
                      Math.round(100 * (timer / timerStartValue)),
                      1
                    )}%`,
                  }}
                />
              </div>
              {processing && (
                <div className="h-full w-full -mb-full bg-white bg-opacity-50 absolute top-0 flex flex-col justify-center">
                  <Spinner size={45} className="text-base-content mx-auto" />
                </div>
              )}
            </div>
          )}
          {paymentError && (
            <div className="h-2/3 mb-40 w-full flex flex-col justify-center">
              <PaymentError />
            </div>
          )}
        </form>
        <OrangeVan className="absolute bottom-0" />
        <RefundPolicy />
        <CopyRight isLink={false} className="text-primary-content" />
      </FullScreen>
    </>
  )
}
