import React from 'react'
import ReactDOM from 'react-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { keycloak } from 'utils'
import App from './App'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
