import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useKioskContext } from 'context/KioskContext'

const supportPhoneNumber = '0897332910'

export const RefundPolicy: FC = () => {
  const { t } = useTranslation()
  const { kiosk } = useKioskContext()

  return (
    <footer className="absolute bottom-2 left-4 rounded w-1/3">
      <div className="text-primary-content whitespace-pre-line text-[8px]">
        <span> {t('payment.privacyPolicy')}</span>
        <span>{t('payment.refundPolicy')}</span>
        <span>
          {t('payment.contactPolicyLine1')}
          <span className="text-base-content font-bold mx-1">
            {kiosk?.contact || supportPhoneNumber}
          </span>
          {t('payment.contactPolicyLine2')}
        </span>
      </div>
    </footer>
  )
}
