import { useKioskContext } from 'context/KioskContext'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'config'
import { drawOverlay, makeBlackAndWhite, makeContrast, makeSepia } from 'utils'
import { ReactComponent as OrangeVan } from 'assets/orange-van.svg'
import { ReactComponent as EllipsePurple } from 'assets/ellipse-purple.svg'
import { Selector } from 'components/base/Selector'

const effects = [(e: File) => e, makeContrast, makeBlackAndWhite, makeSepia]

export const PhotoEffect: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { resultNarrow, setResultNarrow, template, setTemplate, kiosk } =
    useKioskContext()
  const [preview, setPreview] = useState(resultNarrow)
  const [effectPreviews, setEffectPreviews] = useState<File[]>([])
  const [effectIndex, setEffectIndex] = useState(0)
  const [templateIndex, setTemplateIndex] = useState(0)
  const { kioskId } = useParams()
  const templateOverlay = useRef<HTMLImageElement>(null)

  const onDone = useCallback(async () => {
    if (preview)
      await drawOverlay(preview, templateOverlay.current).then((result) =>
        setResultNarrow(result)
      )
    navigate(routes.print.replace(':kioskId', kioskId || ''))
  }, [navigate, kioskId, setResultNarrow, preview])

  const loadPreviews = useCallback(async () => {
    if (resultNarrow)
      Promise.all(effects.map((effect) => effect(resultNarrow))).then(
        (previews) => setEffectPreviews(previews)
      )
  }, [setEffectPreviews, resultNarrow])

  useEffect(() => {
    loadPreviews()
    if (kiosk?.templates && kiosk.templates.length > 0) {
      setTemplate(kiosk.templates[0])
    }
  }, [kiosk, loadPreviews, setTemplate])

  useEffect(() => {
    const timeout = setTimeout(onDone, 120000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onDone])

  const onSelectEffect = (plus: boolean) => () => {
    let index = effectIndex + (plus ? 1 : -1)
    if (effectPreviews[index] === undefined)
      index = plus ? 0 : effectPreviews.length - 1
    setPreview(effectPreviews[index])
    setEffectIndex(index)
  }

  const onSelectTemplate = (plus: boolean) => () => {
    if (!kiosk?.templates) return
    let index = templateIndex + (plus ? 1 : -1)
    if (kiosk.templates[index] === undefined)
      index = plus ? 0 : kiosk.templates.length - 1
    setTemplate(kiosk.templates[index])
    setTemplateIndex(index)
  }

  return (
    <FullScreen className="flex flex-col bg-base-100 justify-center">
      {preview && resultNarrow ? (
        <>
          <p className="text-base-content text-3xl font-[rampart] pt-2 w-full text-center mb-10">
            {t('Customise your print')}
          </p>
          <div className="flex justify-between items-center">
            <div className="w-10" />
            {kiosk?.templates && kiosk.templates.length > 0 ? (
              <Selector
                label={t('effect.chooseTemplate')}
                onBackward={onSelectTemplate(false)}
                onForward={onSelectTemplate(true)}
              />
            ) : (
              <div className="w-96" />
            )}
            <div id="print-preview" className="z-10 mx-36">
              <img
                id="Preview"
                src={URL.createObjectURL(preview)}
                alt="Photo preview."
                className="inline-block"
                style={{ transform: 'rotate(30deg)' }}
                width={200}
                height={600}
              />
              {template && (
                <img
                  ref={templateOverlay}
                  crossOrigin="anonymous"
                  id="PreviewTemplate"
                  src={template?.verticalNarrowUrl}
                  alt="Template preview."
                  className="inline-block"
                  style={{ marginLeft: '-200px', transform: 'rotate(30deg)' }}
                  width={200}
                  height={600}
                />
              )}
              <img
                id="PreviewCopy"
                src={URL.createObjectURL(preview)}
                alt="Photo preview."
                className="inline-block ml-1"
                style={{
                  marginLeft: '-260px',
                  marginRight: '60px',
                  transform: 'rotate(0deg)',
                }}
                width={200}
                height={600}
              />
              {template && (
                <img
                  id="PreviewTemplateCopy"
                  crossOrigin="anonymous"
                  src={template?.verticalNarrowUrl}
                  alt="Template preview."
                  className="inline-block"
                  style={{
                    marginLeft: '-260px',
                    marginRight: '60px',
                    transform: 'rotate(0deg)',
                  }}
                  width={200}
                  height={600}
                />
              )}
            </div>
            {effectPreviews.length > 0 ? (
              <Selector
                label={t('effect.chooseEffect')}
                onBackward={onSelectEffect(false)}
                onForward={onSelectEffect(true)}
              />
            ) : (
              <div className="w-96" />
            )}
            <div className="w-10" />
          </div>
          <div className="absolute top-16 right-16">
            <div
              className="bg-primary hover:bg-primary-focus rounded-full h-18 w-52 z-10"
              onClick={onDone}
            >
              <h2 className="text-center text-white text-xl mt-3 py-6">
                {t('printer.print')}
              </h2>
            </div>
          </div>
        </>
      ) : (
        <h3 className="mx-auto text text-6xl font-serif">
          {t('printer.errorLoadingResult')}
        </h3>
      )}
      <EllipsePurple className="fixed right-0 top-60" />
      <OrangeVan className="absolute bottom-0" />
    </FullScreen>
  )
}
