import './i18n'
import { Admin } from 'pages/Admin'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Download } from 'pages/Download'
import { InputDiscountCode } from 'pages/InputDiscountCode'
import { KioskAdmin } from 'pages/KioskAdmin'
import { KioskIndex } from 'pages/KioskIndex'
import { KioskProvider } from 'context/KioskContext'
import { Loading } from 'components/base/Loading'
import { NotFound } from 'pages/NotFound'
import { Payment } from 'pages/Payment'
import { PhotoAutomat } from 'pages/PhotoAutomat'
import { PhotoEffect } from 'pages/PhotoEffect'
import { Printer } from 'pages/Printer'
import { PrivateRoute } from 'components/base/PrivateRoute'
import { routes } from 'config'
import { SocketProvider } from 'context/SocketContext'
import { Suspense } from 'react'

function App() {
  return (
    <SocketProvider>
      <KioskProvider>
        <Router>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path={routes.admin} element={<PrivateRoute />}>
                <Route path="" element={<Admin />} />
              </Route>
              <Route path={routes.kioskAdmin} element={<PrivateRoute />}>
                <Route path="" element={<KioskAdmin />} />
              </Route>
              <Route path={routes.kiosk} element={<KioskIndex />} />
              <Route path={routes.discount} element={<InputDiscountCode />} />
              <Route path={routes.payment} element={<Payment />} />
              <Route path={routes.paymentWithCode} element={<Payment />} />
              <Route path={routes.photoautomat} element={<PhotoAutomat />} />
              <Route path={routes.photoEffect} element={<PhotoEffect />} />
              <Route path={routes.print} element={<Printer />} />
              <Route path={routes.download} element={<Download />} />
              <Route path={routes.wildCard} element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </KioskProvider>
    </SocketProvider>
  )
}

export default App
