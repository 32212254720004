import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FC, useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GenerateCode } from 'components/GenerateCode'
import { KioskLoader } from 'components/KioskLoader'
import { Kiosk, KioskTab } from 'types'
import { PhotoList } from 'components/PhotoList'
import { routes } from 'config'
import { useKioskContext } from 'context/KioskContext'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import FullScreen from 'react-div-100vh'
import { CreateKioskForm } from 'components/CreateKioskForm'
import { useKeycloak } from '@react-keycloak/web'

export const KioskAdmin: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tab, setTab] = useState(KioskTab.PHOTOS)
  const { kiosk, setKiosk } = useKioskContext()
  const { keycloak } = useKeycloak()

  const goBack = useCallback(() => {
    navigate(routes.admin)
  }, [navigate])

  return (
    <KioskLoader>
      <FullScreen className="bg-base-100 flex flex-col">
        <div className="tabs tabs-boxed bg-base-100 justify-center w-full">
          <FontAwesomeIcon
            onClick={goBack}
            icon={faAngleLeft}
            className="text text-3xl mr-5"
          />
          <a
            className={classNames(
              'tab',
              tab === KioskTab.PHOTOS && 'tab-active'
            )}
            onClick={() => setTab(KioskTab.PHOTOS)}
          >
            {t(`tabs.${KioskTab.PHOTOS.valueOf()}`)}
          </a>
          <a
            className={classNames(
              'tab',
              tab === KioskTab.MANAGE && 'tab-active'
            )}
            onClick={() => setTab(KioskTab.MANAGE)}
          >
            {t(`tabs.${KioskTab.MANAGE.valueOf()}`)}
          </a>
          {(kiosk?.discountAmount || 0) > 0 && (
            <a
              className={classNames(
                'tab',
                tab === KioskTab.GENERATE_DISCOUNT && 'tab-active'
              )}
              onClick={() => setTab(KioskTab.GENERATE_DISCOUNT)}
            >
              {t(`tabs.${KioskTab.GENERATE_DISCOUNT.valueOf()}`)}
            </a>
          )}
        </div>
        {tab === KioskTab.PHOTOS && <PhotoList />}
        {tab === KioskTab.MANAGE && (
          <>
            <div className="pt-8 md:pt-16 pb-2">
              <h1 className="text text-3xl text-center">
                {t('admin.kiosk.manage')}
              </h1>
            </div>
            <CreateKioskForm
              defaultValues={kiosk as Kiosk}
              isAdmin={keycloak.hasRealmRole('admin')}
              onComplete={(data) => {
                setKiosk(data)
                setTab(KioskTab.PHOTOS)
              }}
              onCancel={() => setTab(KioskTab.PHOTOS)}
            />
          </>
        )}
        {tab === KioskTab.GENERATE_DISCOUNT && <GenerateCode />}
      </FullScreen>
    </KioskLoader>
  )
}
