import classNames from 'classnames'
import { Spinner } from 'components/base/Spinner'
import { routes } from 'config'
import { useKioskContext } from 'context/KioskContext'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as OrangeVan } from 'assets/orange-van.svg'
import { ReactComponent as EllipseBlue } from 'assets/ellipse-blue.svg'
import { canvasToFile } from 'utils'

const timerStartValue = 5
const resolution = {
  width: 500,
  height: 1500,
}

export const PhotoAutomat: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const mediaDevices = useRef<MediaDevices>(navigator.mediaDevices)
  const canvasElementRef = useRef<HTMLCanvasElement | null>(null)
  const preview1CanvasElementRef = useRef<HTMLCanvasElement | null>(null)
  const preview2CanvasElementRef = useRef<HTMLCanvasElement | null>(null)
  const preview3CanvasElementRef = useRef<HTMLCanvasElement | null>(null)
  const videoElementRef = useRef<HTMLVideoElement | null>(null)
  const [imageCount, setImageCount] = useState(0)
  const [inputDevice, setInputDevice] = useState<MediaDeviceInfo | null>(null)
  const [timer, setTimer] = useState(timerStartValue)
  const [to, setTo] = useState<NodeJS.Timeout | undefined>()
  const [active, setActive] = useState(false)
  const [playing, setPlaying] = useState(false)
  const { kioskId } = useParams()

  const { setResultNarrow } = useKioskContext()

  const selectDevice = useCallback(() => {
    mediaDevices.current.enumerateDevices().then((devices) => {
      devices.sort((_, d2) => {
        if (d2.kind === 'videoinput' && d2.label === 'EOS Webcam Utility')
          return 1
        return d2.kind === 'videoinput' ? 1 : -1
      })

      const firstDevice = devices[0]
      if (firstDevice.kind === 'videoinput' && firstDevice.deviceId !== '') {
        setInputDevice(firstDevice)
      } else {
        mediaDevices.current.getUserMedia({ video: true }).then(selectDevice)
      }
    })
  }, [setInputDevice])

  const playStream = useCallback((stream: MediaStream) => {
    if (videoElementRef.current) {
      videoElementRef.current.srcObject = stream
      videoElementRef.current.onplay = () => setPlaying(true)
      videoElementRef.current.play()
    }
  }, [])

  useEffect(() => {
    selectDevice()
  }, [selectDevice])

  const drawPreview = useCallback(() => {
    let canvasRef
    switch (imageCount) {
      case 0:
        canvasRef = preview1CanvasElementRef
        break
      case 1:
        canvasRef = preview2CanvasElementRef
        break
      case 2:
        canvasRef = preview3CanvasElementRef
        break
      default:
        break
    }

    if (videoElementRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext(
        '2d'
      ) as CanvasRenderingContext2D
      const canvasWidth = canvasRef.current.width
      context.drawImage(
        videoElementRef.current,
        (canvasWidth - 218) / 2,
        -11,
        218,
        180
      )
    }
  }, [imageCount])

  const takePicture = useCallback(() => {
    if (videoElementRef.current && canvasElementRef.current) {
      drawPreview()
      const context = canvasElementRef.current.getContext(
        '2d'
      ) as CanvasRenderingContext2D
      if (imageCount === 0) {
        context.fillStyle = 'white'
        context.fillRect(
          0,
          0,
          canvasElementRef.current.width,
          canvasElementRef.current.height
        )
      }
      context.drawImage(
        videoElementRef.current,
        (canvasElementRef.current.width - 620) / 2,
        1 + 470 * imageCount,
        620,
        498
      )
      if (imageCount < 2) {
        setImageCount((i) => ++i)
      } else {
        context.fillRect(0, 0, 30, canvasElementRef.current.height)
        context.fillRect(
          canvasElementRef.current.width - 30,
          0,
          canvasElementRef.current.width,
          canvasElementRef.current.height
        )
        context.fillRect(0, 0, canvasElementRef.current.width, 30)
        context.fillRect(0, 470, canvasElementRef.current.width, 30)
        context.fillRect(0, 940, canvasElementRef.current.width, 30)
        context.fillRect(0, 1410, canvasElementRef.current.width, 30)
        setResultNarrow(
          canvasToFile(
            canvasElementRef.current,
            `print_${new Date().valueOf()}.png`
          )
        )
        setActive(false)
      }
    }
  }, [imageCount, setImageCount, setActive, setResultNarrow])

  useEffect(() => {
    if (active && playing) {
      if (timer === 0) {
        if (!to) {
          takePicture()
          const timeOut = setTimeout(() => {
            setTo(undefined)
            setTimer(timerStartValue)
          }, 100)
          setTo(timeOut)
        }
      } else if (!to) {
        const timeOut = setTimeout(() => {
          setTo(undefined)
          setTimer((t) => t - 1)
        }, 1000)
        setTo(timeOut)
      }
    } else if (imageCount === 2) {
      const mediaStream = videoElementRef.current?.srcObject as MediaStream
      mediaStream.getTracks()[0].stop()
      navigate(routes.photoEffect.replace(':kioskId', kioskId || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, active, playing])

  useEffect(() => {
    if (inputDevice) {
      mediaDevices.current
        .getUserMedia({
          video: {
            width: { exact: 720 },
            height: { exact: 576 },
            deviceId: { exact: inputDevice.deviceId },
          },
        })
        .then(playStream)
    }
  }, [inputDevice, playStream])

  return (
    <>
      <FullScreen
        className="flex flex-col bg-base-100 justify-center"
        onClick={() => {
          if (playing && !active) setActive(true)
        }}
      >
        <EllipseBlue className="fixed left-0" />
        {!playing && <Spinner size={45} className="text-primary mx-auto" />}
        <div
          data-test-id="VideoPreviewWrapper"
          className={classNames(
            'relative mx-auto overflow-hidden rounded-lg z-10 mt-4',
            !playing && 'hidden'
          )}
          style={{ height: '32rem', width: '32rem' }}
        >
          <div className="-mx-24">
            <video
              id="VideoPreview"
              ref={videoElementRef}
              className="bg-base-100 rotate-y-180 mx-auto relative -top-8"
              muted
              width={720}
              autoPlay
              playsInline
              controls={false}
            />
          </div>
          {timer > 0 ? (
            <div className="absolute top-0 w-full h-full flex flex-col justify-center">
              {active && (
                <div className="mx-auto border-dashed rounded-full border-2 w-20 h-20 opacity-75 flex flex-col justify-center">
                  <h1 className="text-center text-white text-6xl font-serif">
                    {timer}
                  </h1>
                </div>
              )}
            </div>
          ) : (
            <div
              className="absolute bottom-0  bg-white h-full rounded bg-opacity-75"
              style={{ width: '32rem' }}
            />
          )}
        </div>
        <div
          style={{ width: '32rem' }}
          className={classNames(
            !active && 'hidden',
            'flex space-x-4 mx-auto my-4'
          )}
        >
          <canvas
            id="photo-preview-1"
            className={classNames(
              'rounded-lg z-10 border border-dotted border-base-content bg-base-100',
              !playing && 'hidden'
            )}
            ref={preview1CanvasElementRef}
            width={158}
            height={158}
          />
          <canvas
            id="photo-preview-2"
            className={classNames(
              'rounded-lg z-10 border border-dotted border-base-content bg-base-100',
              !playing && 'hidden'
            )}
            ref={preview2CanvasElementRef}
            width={158}
            height={158}
          />
          <canvas
            id="photo-preview-3"
            className={classNames(
              'rounded-lg z-10 border border-dotted border-base-content bg-base-100',
              !playing && 'hidden'
            )}
            ref={preview3CanvasElementRef}
            width={158}
            height={158}
          />
        </div>
        <div
          className={classNames(
            (!playing || active) && 'hidden',
            'my-4 h-40 z-10'
          )}
        >
          <h1 className="text-center font-[rampart] text text-3xl font-serif my-3">
            {t('automat.startTitle')}
          </h1>
          <h1 className="text-center text text-2xl font-serif">
            {t('automat.startInstruction')}
          </h1>
        </div>
        <canvas
          id="Canvas"
          ref={canvasElementRef}
          width={resolution.width}
          height={resolution.height}
          className="fixed top-0 hidden"
        />
      </FullScreen>
      <OrangeVan className="absolute bottom-0" />
    </>
  )
}
