import classNames from 'classnames'
import { FC } from 'react'
import FullScreen from 'react-div-100vh'
import { Spinner } from './Spinner'

interface LoadingProps {
  className?: string
}

export const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <FullScreen
      className={classNames(
        className,
        'fixed w-full flex justify-center items-center'
      )}
    >
      <Spinner size={45} className="text-base-content" />
    </FullScreen>
  )
}
