import { checkDiscountCode } from 'services'
import { faBackspace } from '@fortawesome/free-solid-svg-icons'
import { FC, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'components/base/Input'
import { routes } from 'config'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EllipseBlack } from 'assets/ellipse-black.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import FullScreen from 'react-div-100vh'
import { BackButton } from 'components/base/BackButton'

const inputs = new Array(16)
  .fill(null)
  .map((_, i) => i.toString(16).toUpperCase())

export const InputDiscountCode: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const { kioskId } = useParams()

  const onBack = useCallback(() => {
    navigate(routes.kiosk.replace(':kioskId', kioskId || ''))
  }, [kioskId, navigate])

  useEffect(() => {
    const timeout = setTimeout(onBack, 60000)
    return () => {
      clearTimeout(timeout)
    }
  }, [onBack])

  const addDigit = useCallback(
    (digit: string) => {
      if (code.length < 6 && digit.length === 1) {
        const newCode = `${code}${digit}`
        setCode(newCode)
        if (newCode.length === 6) {
          checkDiscountCode(kioskId || '', newCode).then(({ data }) =>
            data.valid
              ? navigate(
                  routes.paymentWithCode
                    .replace(':kioskId', kioskId || '')
                    .replace(':discountCode', newCode)
                )
              : setError(t('input.invalid'))
          )
        }
      }
    },
    [code, setCode, navigate, t, kioskId]
  )

  const removeDigit = useCallback(() => {
    setCode((c) => c.slice(0, c.length - 1))
    setError('')
  }, [setCode, setError])

  window.onkeydown = (e) => {
    if (inputs.includes(e.key.toUpperCase())) {
      addDigit(e.key.toUpperCase())
    } else if (e.key === 'Backspace') {
      removeDigit()
    } else if (e.key === 'Delete') {
      setCode('')
    }
  }

  return (
    <FullScreen className="flex flex-col bg-base-100 justify-center text-base-content">
      <BackButton onClick={onBack} />
      <EllipseBlack className="fixed left-0" />
      <div className="mx-auto">
        <Sun
          style={{
            animation: '120s linear infinite spinner',
          }}
          className="mx-auto h-[300px]"
        />
        <div className="relative -top-[150px] mx-auto">
          <div className="-mt-[72px] -mb-[72px]">
            <div className="bg-primary rounded-xl h-36 w-[780px] -mb-40 ml-2" />
            <div className="bg-white rounded-xl h-36 w-[780px] p-12 relative top-0 mt-2">
              <p className="text text-6xl text-center">{code}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="Inputs" className="flex flex-wrap justify-center mt-12">
        {inputs.map((val, ind) => (
          <Input value={val} index={ind} key={val} onInput={addDigit} />
        ))}
        <div className="basis-full h-0" />
        <div style={{ width: '36rem' }} className="pl-2">
          <span className="text-error text-3xl">{error}</span>
        </div>
        <div
          className="w-44 h-20 bg-white hover:bg-slate-100 m-2 rounded-lg shadow-lg"
          onClick={removeDigit}
        >
          <FontAwesomeIcon
            icon={faBackspace}
            className="text text-4xl ml-20 mt-6"
          />
        </div>
        <div className="basis-full h-4" />
        <div style={{ width: '42rem' }} />
      </div>
    </FullScreen>
  )
}
