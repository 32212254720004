import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postKiosk, putKiosk } from 'services'
import { Kiosk, KioskForm } from 'types'

interface CreateKioskFormProps {
  defaultValues: KioskForm
  isAdmin?: boolean
  onComplete: (kiosk: Kiosk) => void
  onCancel?: () => void
}

export const CreateKioskForm: FC<CreateKioskFormProps> = ({
  defaultValues,
  onComplete,
  onCancel,
  isAdmin = false,
}) => {
  const { t } = useTranslation()
  const [data, setData] = useState(defaultValues)
  const [consent, setConsent] = useState(false)
  const [error, setError] = useState<boolean>(false)

  const onSubmit = useCallback(
    () =>
      defaultValues.id
        ? putKiosk(defaultValues.id, data)
            .then(({ data }) => onComplete(data))
            .catch(() => setError(true))
        : postKiosk(data)
            .then(({ data }) => onComplete(data))
            .catch(() => setError(true)),
    [data, onComplete, defaultValues]
  )

  return (
    <div id="CreateKioskForm" className="flex flex-col">
      {isAdmin && (
        <div className="mx-auto mb-1">
          <label className="label">{t('admin.newKiosk.subLabel')}</label>
          <input
            id="sub"
            className="input input-primary w-60"
            value={data.sub}
            required
            minLength={0}
            maxLength={40}
            onChange={(e) => setData((d) => ({ ...d, sub: e.target.value }))}
          />
        </div>
      )}
      <div className="mx-auto mb-1">
        {error ? (
          <label className="label text-error">
            {t('admin.newKiosk.nameNotUnique')}
          </label>
        ) : (
          <label className="label">{t('admin.newKiosk.nameLabel')}</label>
        )}
        <input
          id="name"
          className="input input-primary w-60"
          value={data.name}
          required
          minLength={0}
          maxLength={40}
          onChange={(e) => setData((d) => ({ ...d, name: e.target.value }))}
        />
      </div>
      <div className="mx-auto mb-1">
        <label className="label pb-0">{t('admin.newKiosk.priceLabel')}</label>
        <label className="label pt-0 text-xs whitespace-pre-wrap w-60">
          {t('admin.newKiosk.priceSubLabel')}
        </label>
        <input
          id="priceAmount"
          className="input input-primary w-60"
          type="number"
          value={data.priceAmount / 100}
          min={0}
          max={5000}
          onChange={(e) =>
            setData((d) => ({
              ...d,
              priceAmount: 100 * parseInt(e.target.value, 10),
            }))
          }
        />
      </div>
      <div className="mx-auto mb-1">
        <label className="label pb-0">
          {t('admin.newKiosk.discountLabel')}
        </label>
        <label className="label pt-0 text-xs whitespace-pre-wrap w-60">
          {t('admin.newKiosk.discountSubLabel')}
        </label>
        <input
          id="discountAmount"
          className="input input-primary w-60"
          type="number"
          value={data.discountAmount / 100}
          min={0}
          max={5000}
          onChange={(e) =>
            setData((d) => ({
              ...d,
              discountAmount: 100 * parseInt(e.target.value, 10),
            }))
          }
        />
      </div>
      {isAdmin && (
        <div className="mx-auto mb-1">
          <label className="label pb-0">
            {t('admin.newKiosk.omisePrivateKeyLabel')}
          </label>
          <label className="label pt-0 text-xs whitespace-pre-wrap w-60">
            {t('admin.newKiosk.omisePrivateKeySubLabel')}
          </label>
          <input
            id="omisePrivateKey"
            className="input input-primary w-60"
            onChange={(e) =>
              setData((d) => ({ ...d, omisePrivateKey: e.target.value }))
            }
          />
        </div>
      )}
      {!isAdmin && !defaultValues.id && (
        <div className="mx-auto mt-2 w-60">
          <label className="cursor-pointer label">
            <span className="label-text whitespace-pre-wrap w-52 text-xs pr-1">
              {t('admin.newKiosk.consentForm')}
            </span>
            <input
              type="checkbox"
              checked={consent}
              onChange={() => setConsent((v) => !v)}
              className="checkbox checkbox-primary"
            />
          </label>
        </div>
      )}
      <div className="mx-auto">
        <button
          id="cancel"
          className="btn-error btn-sm w-20 mx-auto mt-4 rounded-lg mr-20"
          onClick={onCancel}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="text-white inline-block align-middle"
          />
        </button>
        <button
          className={classNames(
            'btn-sm w-20 mx-auto mt-4 rounded-lg',
            data.name.length > 0 &&
              (isAdmin || consent || defaultValues.id !== undefined)
              ? 'btn-success'
              : 'btn-disabled bg-base-200'
          )}
          onClick={onSubmit}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className="text-white inline-block align-middle"
          />
        </button>
      </div>
    </div>
  )
}
