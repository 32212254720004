import { FC } from 'react'

interface InputProps {
  value: string
  index: number
  onInput: (value: string) => void
}

export const Input: FC<InputProps> = ({ value, index, onInput }) => (
  <>
    <div
      className="w-20 h-20 bg-white hover:bg-slate-100 m-2 rounded-lg shadow-lg"
      onClick={() => {
        onInput(value)
      }}
    >
      <h3 className="text text-3xl text-center mt-6">{value}</h3>
    </div>
    {index === 7 ? <div className="basis-full h-0" /> : null}
  </>
)
