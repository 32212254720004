import { FC, useCallback, useEffect, useState } from 'react'
import { routes } from 'config'
import { captureException, uploadPhoto } from 'services'
import { useKioskContext } from 'context/KioskContext'
import { ReactComponent as OrangeVan } from 'assets/orange-van.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { ReactComponent as Print } from 'assets/print.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FullScreen from 'react-div-100vh'
import { QRCode } from 'react-qrcode-logo'

export const Printer: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { resultNarrow, transactionId, setTransactionId } = useKioskContext()
  const { kioskId } = useParams()
  const [photoId, setPhotoId] = useState<string | undefined>()

  useEffect(() => {
    const link = document.getElementById('Link')
    link?.click()
    if (resultNarrow && kioskId) {
      uploadPhoto(resultNarrow, kioskId, transactionId)
        .then(({ data }) => setPhotoId(data.id))
        .catch(() =>
          setTimeout(
            () =>
              uploadPhoto(resultNarrow, kioskId, transactionId)
                .then(({ data }) => setPhotoId(data.id))
                .catch(captureException),
            1000
          )
        )
    } else {
      captureException(
        new Error(
          `Result: ${resultNarrow} or kioskId: ${kioskId} are undefined`
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultNarrow, kioskId])

  const onDone = useCallback(() => {
    setTransactionId(undefined)
    navigate(routes.kiosk.replace(':kioskId', kioskId || ''))
  }, [navigate, kioskId, setTransactionId])

  useEffect(() => {
    const timeout = setTimeout(onDone, 60000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onDone])

  return (
    <>
      <FullScreen className="flex flex-col bg-base-100 justify-center text-base-content ">
        {resultNarrow ? (
          <>
            <a
              id="Link"
              href={URL.createObjectURL(resultNarrow)}
              className="hidden"
              download={resultNarrow.name}
            />
            <Sun
              style={{
                animation: '15s linear infinite spinner',
              }}
              className="mx-auto h-[300px]"
            />
            <Print className="mx-auto -mt-52 z-10" />
            <h1 className="text text-3xl font-serif text-center mt-3 z-10">
              {t('printer.printing')}
            </h1>
            <p className="text-3xl font-[rampart] w-full text-center my-24 whitespace-pre">
              {t('Share your moment on Instagram\n@snapp.buddy')}
            </p>
            <div className="absolute top-16 right-16">
              <div
                className="bg-primary hover:bg-primary-focus rounded-full h-18 w-52 z-10"
                onClick={onDone}
              >
                <h2 className="text-center text-white text-xl mt-3 py-6">
                  {t('printer.done')}
                </h2>
              </div>
            </div>
            {photoId && (
              <div className="absolute top-16 left-16 flex flex-col">
                <h1 className="text text-3xl font-serif text-center relative top-0">
                  {t('printer.scanHere')}
                </h1>
                <div className="p-4 mt-4 mx-6 mx-auto">
                  <QRCode
                    logoImage={require('assets/snapp_buddy_square.png')}
                    bgColor="#F6F3ED"
                    value={`${window.location.origin}/download/${photoId}`}
                    size={200}
                    logoPadding={3}
                    removeQrCodeBehindLogo
                    qrStyle="dots"
                    eyeRadius={2}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <h3 className="mx-auto text text-6xl font-serif">
            {t('printer.errorLoadingResult')}
          </h3>
        )}
      </FullScreen>
      <OrangeVan className="absolute bottom-0" />
      <img
        className="absolute bottom-0 left-0 w-1/2"
        src={require('assets/colored-van-left.png')}
      />
      <img
        className="absolute bottom-0 right-0 w-1/2"
        src={require('assets/colored-van-right.png')}
      />
    </>
  )
}
