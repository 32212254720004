import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { FC } from 'react'

export const Selector: FC<{
  label: string
  onBackward?: React.MouseEventHandler<HTMLDivElement>
  onForward?: React.MouseEventHandler<HTMLDivElement>
}> = ({ label, onBackward, onForward }) => {
  const { t } = useTranslation()

  return (
    <div className="flex rounded-full mb-20 items-center justify-center w-96">
      <div
        className="rounded-full pl-4 pr-5 py-3 hover:bg-base-200 mr-5"
        onClick={onBackward}
      >
        <ArrowLeft className="text-primary" width="30" height="45" />
      </div>
      <h2 className="h-full text-base-content text-3xl">{t(label)}</h2>
      <div
        className="rounded-full pl-5 pr-4 py-3 hover:bg-base-200 ml-5"
        onClick={onForward}
      >
        <ArrowLeft className="text-primary rotate-180" width="30" height="45" />
      </div>
    </div>
  )
}
