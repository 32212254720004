import Keycloak from 'keycloak-js'
import {
  KEYCLOAK_REALM_NAME,
  KEYCLOAK_SERVICE_URL,
  KEYCLOAK_CLIENT_ID,
} from 'config/env'

export const keycloak = Keycloak({
  realm: KEYCLOAK_REALM_NAME || 'photoautomat-test',
  url: KEYCLOAK_SERVICE_URL || 'http://localhost:8080/auth',
  clientId: KEYCLOAK_CLIENT_ID || 'photoautomat-frontend',
})
