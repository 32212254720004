import moment, { MomentInput } from 'moment'

export const getWaitingTime = (date: MomentInput) => {
  return moment(date).fromNow()
}

export const formatDateTime = (date: MomentInput, format = 'DD MMM YYYY') => {
  return moment(date).format(format)
}

export const millisecondsToHMS = (ms: number) => {
  const value = Number.isNaN(ms) || ms < 0 ? 0 : ms
  const seconds = Math.floor((value / 1000) % 60)
  const minutes = Math.floor((value / 1000 / 60) % 60)
  const hours = Math.floor((value / 1000 / 3600) % 24)
  return {
    seconds,
    minutes,
    hours,
  }
}

export const getTimeDurationText = (ms: number) => {
  const { seconds, minutes, hours } = millisecondsToHMS(ms)
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':')
}

export const getTimeDiff = (
  before: MomentInput,
  after: MomentInput = undefined
): number => moment(after).diff(moment(before))

export const getTimeSince = (startTime: MomentInput): string => {
  const diff = getTimeDiff(startTime)
  return getTimeDurationText(diff)
}
