import { FC, useCallback, useEffect, useState } from 'react'
import { getPhotos } from 'services'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'components/base/Spinner'
import { Photo } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { formatDateTime } from 'utils'
import moment from 'moment'

export const PhotoList: FC = () => {
  const [photos, setPhotos] = useState<Photo[]>([])
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)
  const [to, setTo] = useState<NodeJS.Timeout | undefined>(undefined)
  const { kioskId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (kioskId)
      getPhotos(kioskId).then(({ data }) => {
        setLoading(false)
        setPhotos(data)
      })
  }, [setPhotos, kioskId])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (copied) {
      const newTo = setTimeout(() => setCopied(false), 3000)
      setTo(newTo)
      return () => {
        clearTimeout(newTo)
      }
    }
  }, [copied, setTo])

  const copy = useCallback(
    (id: string) => () => {
      navigator.clipboard.writeText(`${window.location.origin}/download/${id}`)
      if (to) {
        setCopied(false)
        setTimeout(() => setCopied(true), 100)
      } else setCopied(true)
    },
    [setCopied, to]
  )

  return (
    <>
      <div className="pt-8 md:pt-16 pb-2">
        <h1 className="text text-3xl text-center">{t('admin.kiosk.photos')}</h1>
      </div>
      <div className="h-8 mx-auto">
        <p>
          Today:{' '}
          {
            photos.filter((p) =>
              moment(p.createdAt).isAfter(moment().startOf('D'))
            ).length
          }
        </p>
      </div>
      <div className="flex-1 overflow-scroll scroll-hidden md:mb-20 py-2">
        {loading && (
          <div className="text-center">
            <Spinner size={45} className="text-primary" />
          </div>
        )}
        {photos.map((photo) => (
          <div
            className="p-1 w-60 mx-auto border bg-base-100 text-gray-600 hover:text-primary"
            key={photo.id}
            onClick={copy(photo.id)}
          >
            <p className="text-center w-48 inline-block">
              {formatDateTime(photo.createdAt, 'DD MMM YYYY - HH:mm')}
            </p>
            <FontAwesomeIcon icon={faCopy} className="text text-xl mr-1" />
          </div>
        ))}
      </div>
      <div className="h-20" />
      {copied && (
        <div className="absolute top-28 md:top-36 w-full">
          <div className="bg-accent bg-opacity-50 w-80 p-2 text-center rounded mx-auto">
            <h1>{t('generic.copied')}</h1>
          </div>
        </div>
      )}
    </>
  )
}
