import { createContext, FC, useCallback, useContext, useState } from 'react'
import { getKiosk } from 'services'
import { Kiosk } from 'types'
import { Template } from 'types/template'

export interface IKioskContext {
  kiosk?: Kiosk
  loadKiosk: (kioskId: string) => Promise<void>
  setKiosk: React.Dispatch<React.SetStateAction<Kiosk | undefined>>
  template?: Template
  setTemplate: React.Dispatch<React.SetStateAction<Template | undefined>>
  resultNarrow?: File
  setResultNarrow: React.Dispatch<React.SetStateAction<File | undefined>>
  resultWide?: File
  setResultWide: React.Dispatch<React.SetStateAction<File | undefined>>
  transactionId?: string
  setTransactionId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const notImplemented = async () => {
  throw new Error('not implemented')
}

export const KioskContext = createContext<IKioskContext>({
  loadKiosk: notImplemented,
  setKiosk: notImplemented,
  setTemplate: notImplemented,
  setResultNarrow: notImplemented,
  setResultWide: notImplemented,
  setTransactionId: notImplemented,
})

export const KioskProvider: FC = ({ children }) => {
  const [resultNarrow, setResultNarrow] = useState<File | undefined>()
  const [resultWide, setResultWide] = useState<File | undefined>()
  const [kiosk, setKiosk] = useState<Kiosk | undefined>()
  const [template, setTemplate] = useState<Template | undefined>()
  const [transactionId, setTransactionId] = useState<string | undefined>()

  const loadKiosk = useCallback(
    (kioskId: string) =>
      getKiosk(kioskId).then(({ data }) => {
        setKiosk(data)
      }),
    [setKiosk]
  )

  return (
    <KioskContext.Provider
      value={{
        kiosk,
        loadKiosk,
        setKiosk,
        template,
        setTemplate,
        resultNarrow,
        setResultNarrow,
        resultWide,
        setResultWide,
        transactionId,
        setTransactionId,
      }}
    >
      {children}
    </KioskContext.Provider>
  )
}

export const useKioskContext = (): IKioskContext => useContext(KioskContext)
