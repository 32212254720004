const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
  return new Blob([ia], { type: mime })
}

export const resizeImage = (
  file: File,
  maxWidth: number,
  maxHeight: number
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      const { width, height } = image

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file)
      }

      let newWidth = 0
      let newHeight = 0

      if (width > height) {
        newHeight = height * (maxWidth / width)
        newWidth = maxWidth
      } else {
        newWidth = width * (maxHeight / height)
        newHeight = maxHeight
      }

      const canvas = document.createElement('canvas')
      canvas.width = newWidth
      canvas.height = newHeight

      const context = canvas.getContext('2d')
      if (context) {
        context.drawImage(image, 0, 0, newWidth, newHeight)
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          }
          reject(new Error('Canvas is empty'))
        })
      }
    }
    image.onerror = reject
  })
}

export const canvasToFile = (canvas: HTMLCanvasElement, fileName: string) => {
  const dataUrl = canvas.toDataURL('image/png', 1) // 1.0 is the quality
  const blob = dataURItoBlob(dataUrl)
  return new File([blob], fileName, {
    type: blob.type,
  })
}

export const drawImageFile = (
  file: File,
  draw: (canvas: HTMLCanvasElement, image: HTMLImageElement) => File
): Promise<File> => {
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'))
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(draw(canvas, image))
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export const checkVerticalImg = (file: File): Promise<boolean> => {
  const reader = new FileReader()
  const image = new Image()

  return new Promise((res) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (readerEvent: any) => {
      image.onload = () => {
        const { width, height } = image
        if (width < height) return res(true)
        return res(false)
      }
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export const makeBlackAndWhite = (file: File) => {
  const drawBlackAndWhite = (
    canvas: HTMLCanvasElement,
    image: HTMLImageElement
  ) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imgData.data.length; i += 4) {
      const greyScale =
        (imgData.data[i] + (-95 + imgData.data[i]) / 8) * 0.3 +
        (imgData.data[i + 1] + (-95 + imgData.data[i + 1]) / 8) * 0.59 +
        (imgData.data[i + 2] + (-95 + imgData.data[i + 2]) / 8) * 0.11
      imgData.data[i] = greyScale
      imgData.data[i + 1] = greyScale
      imgData.data[i + 2] = greyScale
      imgData.data[i + 3] = 255
    }
    ctx.putImageData(imgData, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawBlackAndWhite)
}

export const makeSepia = (file: File) => {
  const drawSepia = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imgData.data.length; i += 4) {
      imgData.data[i] = Math.min(
        imgData.data[i] * 0.393 +
          imgData.data[i + 1] * 0.769 +
          imgData.data[i + 2] * 0.189,
        255
      )
      imgData.data[i + 1] = Math.min(
        imgData.data[i] * 0.394 +
          imgData.data[i + 1] * 0.686 +
          imgData.data[i + 2] * 0.168,
        255
      )
      imgData.data[i + 2] = Math.min(
        imgData.data[i] * 0.297 +
          imgData.data[i + 1] * 0.554 +
          imgData.data[i + 2] * 0.159,
        255
      )
      imgData.data[i + 3] = 255
    }
    ctx.putImageData(imgData, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawSepia)
}

export const makeCustom = (file: File) => {
  const drawCustom = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imgData.data.length; i += 4) {
      const greyScale =
        (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3
      imgData.data[i] = Math.min(
        imgData.data[i] + (imgData.data[i] - greyScale) / 2,
        255
      )
      imgData.data[i + 1] = Math.min(
        imgData.data[i + 1] + (imgData.data[i + 1] - greyScale) / 2,
        255
      )
      imgData.data[i + 2] = Math.min(
        imgData.data[i + 2] + (imgData.data[i + 2] - greyScale) / 2,
        255
      )
      imgData.data[i + 3] = 255
    }
    ctx.putImageData(imgData, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawCustom)
}

export const makeContrast = (file: File) => {
  const drawContrast = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imgData.data.length; i += 4) {
      imgData.data[i] = Math.min(
        imgData.data[i] + (-95 + imgData.data[i]) / 12,
        255
      )
      imgData.data[i + 1] = Math.min(
        imgData.data[i + 1] + (-95 + imgData.data[i + 1]) / 12,
        255
      )
      imgData.data[i + 2] = Math.min(
        imgData.data[i + 2] + (-95 + imgData.data[i + 2]) / 12,
        255
      )
      imgData.data[i + 3] = 255
    }
    ctx.putImageData(imgData, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawContrast)
}

export const makeLessContrast = (file: File) => {
  const drawLessContrast = (
    canvas: HTMLCanvasElement,
    image: HTMLImageElement
  ) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    for (let i = 0; i < imgData.data.length; i += 4) {
      const greyScale =
        imgData.data[i] * 0.3 +
        imgData.data[i + 1] * 0.59 +
        imgData.data[i + 2] * 0.11
      imgData.data[i] = Math.max(greyScale, imgData.data[i])
      imgData.data[i + 1] = Math.max(greyScale, imgData.data[i + 1])
      imgData.data[i + 2] = Math.max(greyScale, imgData.data[i + 2])
      imgData.data[i + 3] = 255
    }
    ctx.putImageData(imgData, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawLessContrast)
}

export const drawOverlay = async (
  file: File,
  overlay?: HTMLImageElement | null
) => {
  if (!overlay) return file
  const drawOverlay = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
    canvas.width = image.width
    canvas.height = image.height

    const ctx = canvas.getContext('2d')

    if (ctx === null) throw new Error('Type check fail')

    ctx.drawImage(image, 0, 0)

    if (overlay) ctx.drawImage(overlay, 0, 0)

    return canvasToFile(canvas, `${file.name.split('.')[0]}.png`)
  }

  return drawImageFile(file, drawOverlay)
}
