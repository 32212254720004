export enum AdminTab {
  MY_KIOSKS = 'myKiosks',
  BILLING = 'billing',
}

export enum KioskTab {
  GENERATE_DISCOUNT = 'generateDiscount',
  MANAGE = 'manage',
  PHOTOS = 'photos',
}
