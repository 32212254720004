import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { FC } from 'react'

export const BackButton: FC<{
  onClick?: React.MouseEventHandler<HTMLDivElement>
}> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div
      className="absolute top-16 left-16 flex hover:bg-base-200 rounded-full p-4 items-center"
      onClick={onClick}
    >
      <ArrowLeft className="text-primary mr-2" />
      <h2 className="text-base-content text-2xl mb-1">{t('generic.back')}</h2>
    </div>
  )
}
