import { FC, useCallback, useMemo } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Rays } from 'assets/rays.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { ReactComponent as OrangeVan } from 'assets/orange-van.svg'
import { ReactComponent as TapToStart } from 'assets/tap-to-start.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'config'
import { KioskLoader } from 'components/KioskLoader'
import { useKioskContext } from 'context/KioskContext'

export const KioskIndex: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { kioskId } = useParams()
  const { kiosk } = useKioskContext()

  const showDiscountButton = useMemo(
    () => (kiosk?.priceAmount || 0) > 0 && (kiosk?.discountAmount || 0) > 0,
    [kiosk]
  )

  document.addEventListener('keydown', function (event) {
    if (event.key === 'r') {
      window.location.reload()
    }
  })

  const onContinue = useCallback(() => {
    const canUsePayment =
      (kiosk?.priceAmount || 0) > 0 || kiosk?.discountAmount === 0
    navigate(
      routes[canUsePayment ? 'payment' : 'discount'].replace(
        ':kioskId',
        kioskId || ''
      )
    )
  }, [kiosk, navigate, kioskId])

  return (
    <KioskLoader>
      {showDiscountButton && (
        <div className="absolute top-16 right-16">
          <div
            className="bg-primary hover:bg-primary-focus rounded-full h-18 w-52 z-10"
            onClick={() =>
              navigate(routes.discount.replace(':kioskId', kioskId || ''))
            }
          >
            <h2 className="text-center text-white text-xl mt-3 py-6">
              {t('initial.useDiscount')}
            </h2>
          </div>
        </div>
      )}
      <FullScreen className="absolute w-full overflow-hidden -z-10">
        <Rays className="w-full rotate-180 text-secondary opacity-20" />
      </FullScreen>
      <FullScreen className="flex flex-col justify-center" onClick={onContinue}>
        <div className="mx-auto rounded-full bg-base-100">
          {kiosk?.displayName ? (
            <h1 className="h-28 mx-12 text py-8 text-4xl">
              {kiosk?.displayName}
            </h1>
          ) : (
            <img
              src={require('assets/snapp_buddy_wide.png')}
              className="h-28 mx-3"
            />
          )}
        </div>
        <div className="mx-auto my-8">
          <Sun
            style={{
              animation: '120s linear infinite spinner',
            }}
            className="mx-auto h-[360px]"
          />
          <div className="relative -top-[180px]">
            <div className="-mt-20 -mb-20 bg-base-100 border border-4 rounded-lg border-secondary-focus">
              <TapToStart className="text-primary my-6 h-24" />
            </div>
          </div>
        </div>
        <div className="h-28" />
      </FullScreen>
      <OrangeVan className="absolute bottom-0" />
    </KioskLoader>
  )
}
