import { deletePhoto, getPhoto } from 'services'
import { FC, useCallback, useEffect, useState } from 'react'
import { Loading } from 'components/base/Loading'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FullScreen from 'react-div-100vh'
import { CopyRight } from 'components/base/CopyRight'

export const Download: FC = () => {
  const { t } = useTranslation()
  const { photoId } = useParams()
  const [url, setUrl] = useState<string | undefined>()
  const [error, setError] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false)

  useEffect(() => {
    if (photoId)
      getPhoto(photoId)
        .then(({ data }) => setUrl(data.url))
        .catch(() => setError(true))
  }, [photoId])

  useEffect(() => {
    if (url) {
      const link = document.getElementById('Link')
      link?.click()
    }
  }, [url])

  const onClickDelete = useCallback(() => {
    if (photoId) deletePhoto(photoId).then(() => setDeleted(true))
  }, [photoId, setDeleted])

  return (
    <FullScreen className="flex flex-col bg-base-100 text-base-content justify-center">
      {url ? ( // eslint-disable-line no-nested-ternary
        <>
          <h1 className="mx-auto text text-6xl font-serif mt-20">
            {t('download.thankYou')}
          </h1>
          <a id="Link" href={url} className="mx-auto btn-link mt-4">
            {t('download.manual')}
          </a>
          <span className="mx-auto text-xs mt-20">
            {deleted ? (
              t('download.deleteSuccess')
            ) : (
              <>
                {t('download.permanentlyDelete')}
                <button className="btn-link ml-1" onClick={onClickDelete}>
                  {t('generic.clickHere')}
                </button>
              </>
            )}
          </span>
        </>
      ) : error ? (
        <span className="text-error text-3xl mx-auto">
          {t('download.error')}
        </span>
      ) : (
        <Loading />
      )}
      <CopyRight />
    </FullScreen>
  )
}
